import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Box, Container, Toolbar, Typography } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBack from "components/ArrowBack";
// ==============================|| MAIN LAYOUT ||============================== //

const LibraryLayout = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  let navigate = useNavigate();
  console.log(location.pathname);
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Box
        component="main"
        sx={{
          width: "calc(100% - 260px)",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box style={{ height: "100%" }}>
            <Box
              style={{
                height: "60px",
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
                display: "flex",
                backgroundColor: "#FFF"
              }}

            >
              <ArrowBack color="primary" />
              <Box style={{ width: "calc(100% - 50px)", justifyContent: "center", display: "flex" }}>
                <Typography variant="h3">{"Notification"}</Typography>
              </Box>
              <DeleteIcon />
            </Box>
            <Box style={{ height: "calc(100% - 60px)" }}>
              <Outlet />
            </Box>
          </Box>
          {/* <Footer /> */}
        </Box>
      </Box>
    </Box>
  );
};

export default LibraryLayout;
